.left-green-border {
  border-left: 5px solid #2ecc71 !important;
}

.green-text {
  color: #2ecc71;
}

.left-orange-border {
  border-left: 5px solid #e67e22 !important;
}


.orange-text {
  color: #e67e22;
}

.left-black-border {
  border-left: 5px solid #34495e !important;
}

.black-text {
  color: #34495e;
}

.card {
  border-radius: 10px !important;
}

.card .card-header {
  color: #2980b9 !important;
}

.card table th{
  border: 0 !important;
  color: #2980b9 !important;
}

table .table-image {
  width: 100px;
}
