.fluid-container {
    background-color: #ecf0f1;
}

.dashboard_root {
    margin-top: 60px;
}

.card {
    border: 0;
}

.rupee {
    background-color: black;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    margin: auto;
    line-height: 65px;
    vertical-align: middle;
}

.rupee i {
    color: #e67e22;
}

.rupee_content {
    line-height: 65px;
    margin: auto;
}

.sub-card h6{
    font-size: 12px;
}

.table thead th {
    font-size: 12px;
  }
  
  .table tbody td {
    font-size: 12px;
  }
  
  .table td, .table th {
    padding: .50em;
  }
  
  .table.summary tbody .heading {
    font-size: 12px;
  }
  
  .table.summary tbody td.head {
    font-size: 16px;
    color: #fff;
    vertical-align: middle;
  }
  
  .table.summary td {
    font-size: 16px;
  }
  
  .table.summary tbody .heading {
    font-size: 12px;
  }
  
  .table.summary tbody td.head {
    font-size: 16px;
    color: #fff;
    vertical-align: middle;
  }
  
  .table.summary td {
    font-size: 16px;
  }


  .card .card-table-responsive {
    max-height: 300px;
    overflow-y: scroll;
  }

  .btn-xs {
    font-size: 10px;
    padding: .25rem .5rem;
    line-height: 1.0;
    font-weight: 600;
  }